.ninth {
  padding: 40px 10px;
  position: relative;
  @include tablet {
    padding: 40px;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    @include tablet {
      flex-direction: row-reverse;
    }
  }
  &__contacts {
    display: flex;
    row-gap: 40px;
    flex-direction: column;
    padding-left: 10px;
    &-item {
      display: flex;
      position: relative;
      padding-left: 34px;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      p, a {
        color: #313131;
        font-size: 16px;
        font-weight: 600;
      }
    }
    &-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: var(--gold-color);
      svg {
        width: 50%;
        height: 50%;
      }
    }
  }
  &__map {
    width: 100%;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    @include tablet {
      width: 570px;
      margin-top: 0;
    }
    #map {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 62%;
    }
  }
}