.apartments {
  display: flex;
  column-gap: 40px;
  padding: 20px 28px;
  background: #f1f1f1;
  border-radius: 30px;
  flex-direction: column;
  justify-content: space-between;
  @include tablet {
    padding: 40px;
    flex-direction: row;
  }
  &__wrapper {
    display: flex;
    row-gap: 40px;
    column-gap: 40px;
    justify-content: space-between;
  }

  &__image {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 18px;
    @include tablet {
      width: 446px;
      height: 232px;
      border-radius: 30px;
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 50%;
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__content {
    row-gap: 30px;
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    @include tablet {
      row-gap: 40px;
      margin-top: 0;
    }
    &-desc {
      h2 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 24px;
        }
        @include desktop {
          font-size: 21px;
        }
        @include wide {
          font-size: 24px;
        }
      }
      p {
        font-size: 14px;
        line-height: 140%;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 18px;
        }
        @include desktop {
          font-size: 18px;
        }

        br {
          display: none;
          @include tablet {
            display: block;
          }
        }
      }
    }

    &-buttons {
      display: flex;
      row-gap: 12px;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
        column-gap: 20px;
      }
    }
  }
}

