.sixth {
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;

  &__content {
    width: 100%;
    display: flex;
    row-gap: 60px;
    position: relative;
    flex-direction: column;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.client {
  display: block;
  img {
    width: 80%;
    @include tablet {
      width: 70%;
    }
    @include desktop {
      width: auto;
    }
  }
}