.third {
  padding: 20px 10px;
  position: relative;
  @include tablet {
    padding: 40px;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    @include tablet {
      flex-direction: row-reverse;
    }
    &-text {
      display: flex;
      row-gap: 30px;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font-size: 21px;
        line-height: 120%;
        @include tablet {
          font-size: 24px;
        }
        @include desktop {
          font-size: 26px;
        }
        @include wide {
          font-size: 32px;
        }
        br {
          display: none;
          @include tablet {
            display: block;
          }
        }
      }
      p {
        color: #313131;
        font-size: 14px;
        @include tablet {
          font-size: 14px;
        }
        @include desktop {
          font-size: 14px;
        }
        @include wide {
          font-size: 16px;
        }
      }
    }
  }
}