.seventh {
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;

  &__content {
    padding: 0 40px;
    position: relative;
    margin-left: -20px;
    width: calc(100% + 40px);
    @include tablet {
      width: auto;
      margin-left: 0;
      padding: 40px 60px;
    }
    @include desktop {
      width: auto;
      padding: 60px;
      margin-left: 0;
    }
  }

  &__slider {
    width: 100%;
    &-arrow {
      top: 0;
      bottom: 0;
      display: flex;
      user-select: none;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 36px;
      height: 36px;
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      border-radius: 100%;
      border: 1px solid var(--gold-color);
      @include tablet {
        width: 48px;
        height: 48px;
      }
      svg {
        width: 20px;
      }
      &--prev {
        left: 0;
        svg {
          transform: rotate(-180deg);
        }
      }
      &--next {
        right: 0;
        svg {
          transform: rotate(0deg);
        }
      }

      &.slick-disabled {
        opacity: 0.7;
      }
    }
  }

  &__slide {
    display: flex;
    padding: 0 15px;
    overflow: hidden;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
  }
}