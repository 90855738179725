.question {
  display: none;
  padding-bottom: 0;
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;
  @include desktop {
    display: block;
  }
  &__content {
    padding: 0 60px;
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &-title {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
      div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }
    h1 {
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    a {
      padding: 0 60px;
    }
  }
}