.header {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 100;
  padding: 0 10px;
  @include tablet {
    top: 40px;
    display: flex;
    padding: 0 40px;
  }
  @include wide {
    padding: 0;
  }
  &--wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container();
  }

  &__logo {
    display: flex;
    column-gap: 20px;
    align-items: center;
    img {
      flex-shrink: 0;
    }
    p {
      display: none;
      color: #000;
      font-size: 16px;
      font-weight: 500;
      line-height: 130%;
      @include tablet {
        display: block;
      }
      @include desktop {
        font-size: 14px;
      }
      @include wide {
        font-size: 16px;
      }
      b {
        font-weight: 600;
      }
    }
  }

  &__contacts {
    display: flex;
    column-gap: 20px;
    align-items: center;
    p {
      display: none;
      font-size: 14px;
      line-height: 130%;
      @include wide {
        display: block;
        font-size: 16px;
      }
    }
  }
}