@charset "UTF-8";
/* Base */ /* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --container-width: 1200px;
  --container-padding: 15px;
  --font-main: sans-serif;
  --font-accent: "Montserrat", sans-serif;
  --font-titles: var(--font-accent);
  --gold-color: #BC8C46;
  --blue-color: #0040A1;
  --page-bg: #fff;
  --text-color: #000;
  --accent: #ac182c;
  --link-color: #2578c8;
  --green-color: #5E8058;
  --laptop-size: 1199px;
  --tablet-size: 959px;
  --mobile-size: 599px;
}

html {
  overflow-x: hidden;
  background-color: #fff;
  scroll-behavior: smooth;
  font-family: var(--font-main);
}
html.modal--opened {
  overflow: hidden;
}

body {
  padding: 1% 1% 0 1%;
  background: #E8E8E8;
  color: var(--text-color);
  font-family: var(--font-main);
}
body.modal--opened {
  overflow: hidden;
}

main {
  display: flex;
  row-gap: 61px;
  flex-direction: column;
}

section {
  width: 100%;
  padding: 40px 15px;
  background: #fff;
  position: relative;
  border-radius: 20px;
}
@media (min-width: 768px) {
  section {
    padding: 80px 40px;
  }
}
section:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 60%;
  height: 1px;
  margin: auto;
  bottom: -31px;
  background: var(--gold-color);
}
section:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
section:last-child:after {
  display: none;
}

.container {
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    padding: 0;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

img {
  display: block;
}

a {
  color: var(--link-color);
}

.react-datepicker-wrapper {
  width: 100%;
}

.none {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

/* Blocks */
.accordion {
  width: 100%;
}
.accordion-item {
  padding: 12px 0;
  border-top: 1px solid var(--gold-color);
}
@media (min-width: 768px) {
  .accordion-item {
    padding: 16px 0;
  }
}
.accordion-header {
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  position: relative;
  padding: 4px 60px 4px 0;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .accordion-header {
    padding: 6px 0;
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .accordion-header {
    font-size: 20px;
    padding: 10px 0;
  }
}
.accordion-header-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
}
@media (min-width: 768px) {
  .accordion-header-icon {
    width: 40px;
    height: 40px;
  }
}
.accordion-header-icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  background: var(--green-color);
  background-color: transparent;
  background-image: image-set(url("./../img/bottom-arrow.png") 1x, url("./../img/bottom-arrow@2x.png") 2x);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.accordion-content {
  color: #000;
  display: none;
  font-size: 14px;
  padding: 10px 0;
  line-height: 140%;
  font-family: var(--font-accent);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (min-width: 768px) {
  .accordion-content {
    font-size: 16px;
  }
}
.accordion-content ul {
  margin-top: 10px;
}
.accordion-content ul li {
  color: #000;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 8px;
  position: relative;
  font-family: var(--font-accent);
  padding-left: 20px;
}
.accordion-content ul li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 6px;
  height: 6px;
  margin: auto;
  background: #000;
  border-radius: 100%;
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-item.active .accordion-header-icon:after {
  transform: rotate(-180deg);
}

.apartments {
  display: flex;
  column-gap: 40px;
  padding: 20px 28px;
  background: #f1f1f1;
  border-radius: 30px;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .apartments {
    padding: 40px;
    flex-direction: row;
  }
}
.apartments__wrapper {
  display: flex;
  row-gap: 40px;
  column-gap: 40px;
  justify-content: space-between;
}
.apartments__image {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 18px;
}
@media (min-width: 768px) {
  .apartments__image {
    width: 446px;
    height: 232px;
    border-radius: 30px;
  }
}
.apartments__image:after {
  content: "";
  display: block;
  padding-bottom: 50%;
}
.apartments__image img {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.apartments__content {
  row-gap: 30px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .apartments__content {
    row-gap: 40px;
    margin-top: 0;
  }
}
.apartments__content-desc h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .apartments__content-desc h2 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .apartments__content-desc h2 {
    font-size: 21px;
  }
}
@media (min-width: 1300px) {
  .apartments__content-desc h2 {
    font-size: 24px;
  }
}
.apartments__content-desc p {
  font-size: 14px;
  line-height: 140%;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .apartments__content-desc p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .apartments__content-desc p {
    font-size: 18px;
  }
}
.apartments__content-desc p br {
  display: none;
}
@media (min-width: 768px) {
  .apartments__content-desc p br {
    display: block;
  }
}
.apartments__content-buttons {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .apartments__content-buttons {
    flex-direction: row;
    column-gap: 20px;
  }
}

.button {
  height: 50px;
  color: #fff;
  border: none;
  display: flex;
  padding: 0 24px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  align-items: center;
  border-radius: 6px;
  white-space: nowrap;
  justify-content: center;
  background: var(--gold-color);
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .button {
    height: 52px;
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .button {
    height: 52px;
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .button {
    height: 58px;
    font-size: 15px;
  }
}
.button:hover {
  background: var(--blue-color);
}
.button--blue {
  background: var(--blue-color);
}
.button--blue:hover {
  background: var(--gold-color);
}

.footer {
  padding: 32px 0 80px 0;
  background: #f1f1f1;
}
@media (min-width: 768px) {
  .footer {
    padding: 40px 0 60px 0;
  }
}
.footer--wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .footer--wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .footer--wrapper {
    padding: 0;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .footer--wrapper {
    max-width: 1200px;
  }
}
.footer__menu {
  display: none;
  column-gap: 20px;
}
@media (min-width: 768px) {
  .footer__menu {
    display: flex;
  }
}
.footer__menu li a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-accent);
  position: relative;
}
.footer__menu li a:after {
  left: 0;
  width: 0;
  bottom: -4px;
  content: "";
  height: 1px;
  background: #000;
  position: absolute;
  transition: 0.3s ease-in all;
}
.footer__menu li a:hover:after {
  width: 100%;
  transition: 0.3s ease-in all;
}
.footer__logo {
  display: flex;
  column-gap: 12px;
  margin-bottom: 20px;
  align-items: center;
}
@media (min-width: 768px) {
  .footer__logo {
    margin: 40px 0;
  }
}
.footer__logo img {
  flex-shrink: 0;
}
.footer__logo span {
  color: #000;
  opacity: 0.4;
  font-size: 14px;
}
.footer__logo p {
  font-size: 21px;
  font-weight: 600;
}
.footer__copyright {
  text-align: center;
}
.footer__copyright p, .footer__copyright a {
  color: #000;
  opacity: 0.7;
  line-height: 140%;
}
.footer__copyright a {
  text-decoration: underline;
}

.header {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 100;
  padding: 0 10px;
}
@media (min-width: 768px) {
  .header {
    top: 40px;
    display: flex;
    padding: 0 40px;
  }
}
@media (min-width: 1300px) {
  .header {
    padding: 0;
  }
}
.header--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .header--wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .header--wrapper {
    padding: 0;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .header--wrapper {
    max-width: 1200px;
  }
}
.header__logo {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.header__logo img {
  flex-shrink: 0;
}
.header__logo p {
  display: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
}
@media (min-width: 768px) {
  .header__logo p {
    display: block;
  }
}
@media (min-width: 992px) {
  .header__logo p {
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .header__logo p {
    font-size: 16px;
  }
}
.header__logo p b {
  font-weight: 600;
}
.header__contacts {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.header__contacts p {
  display: none;
  font-size: 14px;
  line-height: 130%;
}
@media (min-width: 1300px) {
  .header__contacts p {
    display: block;
    font-size: 16px;
  }
}

.room {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: #fff;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}
.room__close {
  cursor: pointer;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  color: #1562D7;
  font-size: 2em;
  background: transparent;
  z-index: 10;
}
.room__wrapper {
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2.5em 0;
  overflow-x: auto;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}
.room__item {
  display: none;
  row-gap: 20px;
  margin: 0 auto;
  padding: 0 15px;
  column-gap: 40px;
  max-width: 1000px;
  align-items: center;
}
@media (min-width: 768px) {
  .room__item {
    padding: 0;
    row-gap: 40px;
  }
}
.room__item.room--active {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .room__item.room--active {
    flex-direction: row;
  }
}
.room__item-slider {
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
}
@media (min-width: 768px) {
  .room__item-slider {
    width: 760px;
  }
}
.room__item-slider:after {
  content: "";
  display: block;
  padding-bottom: 70%;
}
.room__item-slider .aparts-slider {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.room__item-slider .aparts-slider img {
  height: 100%;
}
.room__item-slider .aparts-slider .slick-track,
.room__item-slider .aparts-slider .slick-list {
  height: 100%;
}
.room__item-slider .aparts-slider .slick-arrow {
  top: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 36px;
  height: 36px;
  z-index: 1000;
  cursor: pointer;
  background: #fff;
  color: transparent;
  position: absolute;
  border-radius: 100%;
}
.room__item-slider .aparts-slider .slick-arrow:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  background: url("../img/bottom-arrow.png") no-repeat center center;
  background-size: contain;
}
.room__item-slider .aparts-slider .slick-arrow.slick-next {
  right: 12px;
  transform: rotate(-90deg);
}
.room__item-slider .aparts-slider .slick-arrow.slick-prev {
  left: 12px;
}
.room__item-slider .aparts-slider .slick-arrow.slick-prev:after {
  transform: rotate(90deg);
}
.room__item-slider .aparts-slider .slick-arrow.slick-disabled {
  opacity: 0.7;
}
.room__item-description {
  width: 100%;
}
.room__item-description h2 {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .room__item-description h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.room__item-description p {
  font-size: 14px;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .room__item-description p {
    font-size: 16px;
  }
}
.room__item-description button {
  margin-top: 30px;
}
.room__controls {
  left: 0;
  right: 0;
  top: 24px;
  width: 92%;
  margin: auto;
  position: absolute;
}
@media (min-width: 768px) {
  .room__controls {
    width: 97%;
  }
}
.room__controls-close, .room__controls-back {
  position: absolute;
  top: 0;
  z-index: 10;
}
.room__controls-close {
  right: 0;
}
.room__controls-back {
  left: 0;
  position: relative;
  padding-left: 20px;
}
.room__controls-back:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  background: url("./../img/bottom-arrow.png") no-repeat center center;
  background-size: contain;
  transform: rotate(90deg);
}

.room--active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: all 0.4s ease-in-out;
}
.room--active .room__wrapper {
  opacity: 1;
  pointer-events: all;
  transition: all 0.4s ease-in-out;
}

.react {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1011;
  overflow-x: auto;
  padding: 40px 0;
  opacity: 0;
  background: #eff1f4;
  pointer-events: none;
  transition: 0.3s ease-in all;
}
.react.react--active {
  opacity: 1;
  pointer-events: all;
  transition: 0.3s ease-in all;
}
.react__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.react__controls {
  left: 0;
  right: 0;
  top: 24px;
  width: 92%;
  margin: auto;
  position: absolute;
}
@media (min-width: 768px) {
  .react__controls {
    width: 97%;
  }
}
.react__controls-close, .react__controls-back {
  position: absolute;
  top: 0;
  z-index: 10;
}
.react__controls-close {
  right: 0;
}
.react__controls-back {
  left: 0;
  position: relative;
  padding-left: 20px;
}
.react__controls-back:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  background: url("./../img/bottom-arrow.png") no-repeat center center;
  background-size: contain;
  transform: rotate(90deg);
}

.reservation {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .reservation {
    padding: 0;
  }
}
.reservation__controls {
  left: 0;
  right: 0;
  top: 24px;
  width: 92%;
  margin: auto;
  position: absolute;
}
@media (min-width: 768px) {
  .reservation__controls {
    width: 97%;
  }
}
.reservation__controls-close, .reservation__controls-back {
  position: absolute;
  top: 0;
  z-index: 10;
}
.reservation__controls-close {
  right: 0;
}
.reservation__controls-back {
  left: 0;
  position: relative;
  padding-left: 20px;
}
.reservation__controls-back:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
  height: 10px;
  background: url("./../img/bottom-arrow.png") no-repeat center center;
  background-size: contain;
  transform: rotate(90deg);
}
.reservation__title {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .reservation__title {
    font-size: 42px;
  }
}
.reservation__subtitle {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .reservation__subtitle {
    font-size: 18px;
  }
}
.reservation__container {
  background: #fff;
  border-radius: 30px;
}
@media (min-width: 768px) {
  .reservation__container {
    display: flex;
  }
}
.reservation__subtitle {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .reservation__subtitle {
    font-size: 21px;
  }
}
.reservation__personal {
  width: 100%;
  padding: 24px 20px;
}
@media (min-width: 768px) {
  .reservation__personal {
    width: 45%;
    padding: 32px 40px;
  }
}
.reservation__room {
  width: 100%;
  background: #fafafa;
  padding: 24px 20px 0 20px;
}
@media (min-width: 768px) {
  .reservation__room {
    width: 55%;
    padding: 32px 40px 0 40px;
  }
}
.reservation__buttons {
  width: 100%;
  margin-top: 20px;
}

.selector {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
}
.selector:last-child {
  border-bottom: none;
}
.selector__photo {
  display: flex;
  column-gap: 14px;
  align-items: center;
}
.selector__image {
  width: 100px;
  height: 50px;
  display: none;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}
@media (min-width: 768px) {
  .selector__image {
    display: block;
  }
}
.selector__image img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.selector__name h3 {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-accent);
}
@media (min-width: 768px) {
  .selector__name h3 {
    font-size: 18px;
  }
}
.selector__name p {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 300;
  font-family: var(--font-accent);
}
.selector__counter {
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.selector__counter-control {
  width: 32px;
  color: #fff;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
  border-radius: 100%;
  background: var(--green-color);
}
.selector__counter-control--minus:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' className='s__pMo8XJ4PYXwDYpRlQu2f' data-test-id='icon' style='display: inline-block;'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 8.75H2v-1.5h12v1.5Z'%3E%3C/path%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
}
.selector__counter-control--plus:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='s__pMo8XJ4PYXwDYpRlQu2f' data-test-id='icon' style='display: inline-block;'%3E%3Cpath d='M8.75 7.25V2h-1.5v5.25H2v1.5h5.25V14h1.5V8.75H14v-1.5H8.75Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}
.selector__counter-number {
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .selector__counter-number {
    font-size: 21px;
  }
}

.error-message {
  color: red;
  display: block;
  font-size: 12px;
  margin-top: 2px;
}

.title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .title {
    margin-bottom: 60px;
  }
}
.title h1 {
  font-size: 24px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .title h1 {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .title h1 {
    font-size: 32px;
  }
}
@media (min-width: 1300px) {
  .title h1 {
    font-size: 40px;
  }
}
.title h2 {
  font-size: 21px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .title h2 {
    font-size: 32px;
    margin-top: 20px;
  }
}

.fifth {
  padding: 40px 10px;
  position: relative;
}
@media (min-width: 768px) {
  .fifth {
    padding: 40px;
  }
}
.fifth__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .fifth__content {
    flex-direction: row-reverse;
  }
}
.fifth__content-text {
  display: flex;
  row-gap: 30px;
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .fifth__content-text {
    margin-top: 0;
  }
}
.fifth__content-text h1 {
  font-size: 21px;
  font-weight: 600;
  line-height: 120%;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .fifth__content-text h1 {
    font-size: 32px;
  }
}
@media (min-width: 992px) {
  .fifth__content-text h1 {
    font-size: 30px;
  }
}
@media (min-width: 1300px) {
  .fifth__content-text h1 {
    font-size: 40px;
  }
}
.fifth__content-text h2 {
  font-size: 21px;
  font-weight: 500;
  line-height: 120%;
}
@media (min-width: 768px) {
  .fifth__content-text h2 {
    font-size: 21px;
  }
}
@media (min-width: 992px) {
  .fifth__content-text h2 {
    font-size: 26px;
  }
}
@media (min-width: 1300px) {
  .fifth__content-text h2 {
    font-size: 28px;
  }
}
.fifth__content-text h2 br {
  display: none;
}
@media (min-width: 768px) {
  .fifth__content-text h2 br {
    display: block;
  }
}
.fifth__content-text p {
  color: #313131;
  font-size: 14px;
}
@media (min-width: 768px) {
  .fifth__content-text p {
    font-size: 16px;
  }
}

.first {
  position: relative;
  padding-top: 140px;
  background: #fff url("../img/first-bg.png") no-repeat right bottom;
  background-size: contain;
}
@media (min-width: 768px) {
  .first {
    padding-top: 180px;
    background-size: inherit;
  }
}
.first__title {
  text-align: center;
}
.first__title h1 {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .first__title h1 {
    font-size: 40px;
  }
}
.first__title h1 span {
  font-size: 18px;
  font-weight: 400;
}
@media (min-width: 768px) {
  .first__title h1 span {
    font-size: 32px;
  }
}
.first__content {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .first__content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-around;
  }
}
.first__list {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .first__list {
    margin-top: 0;
  }
}
.first__list-ul li {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding-left: 24px;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .first__list-ul li {
    font-size: 16px;
    padding-left: 30px;
    margin-bottom: 24px;
  }
}
.first__list-ul li:last-child {
  margin-bottom: 0;
}
.first__list-ul li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: var(--gold-color);
}
@media (min-width: 768px) {
  .first__list-ul li:after {
    width: 14px;
    height: 14px;
  }
}
.first__list-call {
  margin-top: 50px;
}
.first__list-call p {
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .first__list-call p {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .first__list-call p {
    font-size: 18px;
  }
}
@media (min-width: 1300px) {
  .first__list-call p {
    font-size: 20px;
  }
}

.four {
  position: relative;
}
.four__items {
  display: grid;
  row-gap: 100px;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (min-width: 992px) {
  .four__items {
    row-gap: 60px;
    column-gap: 20px;
  }
}
@media (min-width: 1300px) {
  .four__items {
    row-gap: 100px;
    column-gap: 20px;
  }
}
.four__content {
  padding: 0 40px;
  position: relative;
  margin-left: -20px;
  width: calc(100% + 40px);
}
@media (min-width: 768px) {
  .four__content {
    padding: 0;
    width: 100%;
    position: relative;
  }
}
.four__slider {
  width: 100%;
}
.four__slider-arrow {
  top: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 36px;
  height: 36px;
  z-index: 1000;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  border: 1px solid var(--gold-color);
}
@media (min-width: 768px) {
  .four__slider-arrow {
    width: 48px;
    height: 48px;
    display: none;
  }
}
.four__slider-arrow svg {
  width: 20px;
}
.four__slider-arrow--prev {
  left: 0;
}
.four__slider-arrow--prev svg {
  transform: rotate(-180deg);
}
.four__slider-arrow--next {
  right: 0;
}
.four__slider-arrow--next svg {
  transform: rotate(0deg);
}
.four__slider-arrow.slick-disabled {
  opacity: 0.7;
}

.pack {
  width: 100%;
}
@media (min-width: 992px) {
  .pack {
    width: 100%;
  }
}
@media (min-width: 1300px) {
  .pack {
    width: 295px;
    margin: 0 auto;
  }
}
.pack__image img {
  width: 100%;
  border-radius: 12px;
}
.pack__desc {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  padding: 20px 0 0 12px;
}
.pack__desc h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}
.pack__desc p {
  color: #313131;
  font-size: 16px;
  line-height: 140%;
}
@media (min-width: 768px) {
  .pack__desc p {
    font-size: 16px;
  }
}
.pack__desc p br {
  display: none;
}
@media (min-width: 768px) {
  .pack__desc p br {
    display: block;
  }
}

.ninth {
  padding: 40px 10px;
  position: relative;
}
@media (min-width: 768px) {
  .ninth {
    padding: 40px;
  }
}
.ninth__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .ninth__content {
    flex-direction: row-reverse;
  }
}
.ninth__contacts {
  display: flex;
  row-gap: 40px;
  flex-direction: column;
  padding-left: 10px;
}
.ninth__contacts-item {
  display: flex;
  position: relative;
  padding-left: 34px;
  margin-bottom: 24px;
}
.ninth__contacts-item:last-child {
  margin-bottom: 0;
}
.ninth__contacts-item p, .ninth__contacts-item a {
  color: #313131;
  font-size: 16px;
  font-weight: 600;
}
.ninth__contacts-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: var(--gold-color);
}
.ninth__contacts-icon svg {
  width: 50%;
  height: 50%;
}
.ninth__map {
  width: 100%;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}
@media (min-width: 768px) {
  .ninth__map {
    width: 570px;
    margin-top: 0;
  }
}
.ninth__map #map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.ninth__map:after {
  content: "";
  display: block;
  padding-bottom: 62%;
}

.question {
  display: none;
  padding-bottom: 0;
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;
}
@media (min-width: 992px) {
  .question {
    display: block;
  }
}
.question__content {
  padding: 0 60px;
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.question__content-title {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}
.question__content-title div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.question__content h1 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}
.question__content a {
  padding: 0 60px;
}

.second {
  position: relative;
  background: #fff url("../img/second-bg.png") no-repeat center bottom;
}
.second__items {
  width: 100%;
  display: flex;
  row-gap: 20px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .second__items {
    column-gap: 20px;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .second__items {
    row-gap: 0;
    column-gap: 0;
    flex-direction: row;
  }
}
.second__items--small {
  max-width: 780px;
}
.second__items--big {
  max-width: 1080px;
}
.second__product {
  display: none;
}
@media (min-width: 992px) {
  .second__product {
    width: 38%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
@media (min-width: 1300px) {
  .second__product {
    width: auto;
  }
}
.second__content {
  width: 100%;
  display: flex;
  row-gap: 20px;
  position: relative;
  flex-direction: column;
}
@media (min-width: 768px) {
  .second__content {
    row-gap: 20px;
  }
}
@media (min-width: 992px) {
  .second__content {
    row-gap: 60px;
  }
}

.benefit {
  padding: 14px;
  display: flex;
  column-gap: 12px;
  border-radius: 10px;
  background: #E8E8E8;
}
@media (min-width: 768px) {
  .benefit {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .benefit {
    width: auto;
  }
}
.benefit__icon {
  width: 36px;
  height: 36px;
  display: flex;
  flex-shrink: 0;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  background: var(--gold-color);
}
.benefit__icon svg {
  width: 60%;
  height: 60%;
}
.benefit__desc h3 {
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 992px) {
  .benefit__desc h3 {
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .benefit__desc h3 {
    font-size: 16px;
  }
}
.benefit__desc p {
  color: #2E2E2E;
  margin-top: 8px;
  line-height: 120%;
}
@media (min-width: 992px) {
  .benefit__desc p {
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .benefit__desc p {
    font-size: 16px;
  }
}
.benefit__desc p br {
  display: none;
}
@media (min-width: 768px) {
  .benefit__desc p br {
    display: block;
  }
}

.seventh {
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;
}
.seventh__content {
  padding: 0 40px;
  position: relative;
  margin-left: -20px;
  width: calc(100% + 40px);
}
@media (min-width: 768px) {
  .seventh__content {
    width: auto;
    margin-left: 0;
    padding: 40px 60px;
  }
}
@media (min-width: 992px) {
  .seventh__content {
    width: auto;
    padding: 60px;
    margin-left: 0;
  }
}
.seventh__slider {
  width: 100%;
}
.seventh__slider-arrow {
  top: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 36px;
  height: 36px;
  z-index: 1000;
  cursor: pointer;
  position: absolute;
  border-radius: 100%;
  border: 1px solid var(--gold-color);
}
@media (min-width: 768px) {
  .seventh__slider-arrow {
    width: 48px;
    height: 48px;
  }
}
.seventh__slider-arrow svg {
  width: 20px;
}
.seventh__slider-arrow--prev {
  left: 0;
}
.seventh__slider-arrow--prev svg {
  transform: rotate(-180deg);
}
.seventh__slider-arrow--next {
  right: 0;
}
.seventh__slider-arrow--next svg {
  transform: rotate(0deg);
}
.seventh__slider-arrow.slick-disabled {
  opacity: 0.7;
}
.seventh__slide {
  display: flex;
  padding: 0 15px;
  overflow: hidden;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.sixth {
  position: relative;
  background: #fff url("../img/sixth-bg.png") no-repeat center bottom;
}
.sixth__content {
  width: 100%;
  display: flex;
  row-gap: 60px;
  position: relative;
  flex-direction: column;
}
.sixth__row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.client {
  display: block;
}
.client img {
  width: 80%;
}
@media (min-width: 768px) {
  .client img {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .client img {
    width: auto;
  }
}

.third {
  padding: 20px 10px;
  position: relative;
}
@media (min-width: 768px) {
  .third {
    padding: 40px;
  }
}
.third__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .third__content {
    flex-direction: row-reverse;
  }
}
.third__content-text {
  display: flex;
  row-gap: 30px;
  flex-direction: column;
  align-items: flex-start;
}
.third__content-text h1 {
  font-size: 21px;
  line-height: 120%;
}
@media (min-width: 768px) {
  .third__content-text h1 {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .third__content-text h1 {
    font-size: 26px;
  }
}
@media (min-width: 1300px) {
  .third__content-text h1 {
    font-size: 32px;
  }
}
.third__content-text h1 br {
  display: none;
}
@media (min-width: 768px) {
  .third__content-text h1 br {
    display: block;
  }
}
.third__content-text p {
  color: #313131;
  font-size: 14px;
}
@media (min-width: 768px) {
  .third__content-text p {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .third__content-text p {
    font-size: 14px;
  }
}
@media (min-width: 1300px) {
  .third__content-text p {
    font-size: 16px;
  }
}

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */