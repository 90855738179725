/* Base */
@import './base/reset';
@import './base/vars';
@import './base/mixins';
@import './base/base';
@import './base/utils';

/* Blocks */
@import "blocks/_accordion.scss";
@import "blocks/_apartments.scss";
@import "blocks/_button.scss";
@import "blocks/_footer.scss";
@import "blocks/_header.scss";
@import "blocks/_modal.scss";
@import "blocks/_titles.scss";
@import "sections/_fifth.scss";
@import "sections/_first.scss";
@import "sections/_four.scss";
@import "sections/_ninth.scss";
@import "sections/_question.scss";
@import "sections/_second.scss";
@import "sections/_seventh.scss";
@import "sections/_sixth.scss";
@import "sections/_third.scss";

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */
