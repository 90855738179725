.four {
  position: relative;
  &__items {
    display: grid;
    row-gap: 100px;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    @include desktop {
      row-gap: 60px;
      column-gap: 20px;
    }
    @include wide {
      row-gap: 100px;
      column-gap: 20px;
    }
  }

  &__content {
    padding: 0 40px;
    position: relative;
    margin-left: -20px;
    width: calc(100% + 40px);
    @include tablet {
      padding: 0;
      width: 100%;
      position: relative;
    }
  }

  &__slider {
    width: 100%;
    &-arrow {
      top: 0;
      bottom: 0;
      display: flex;
      user-select: none;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 36px;
      height: 36px;
      z-index: 1000;
      cursor: pointer;
      position: absolute;
      border-radius: 100%;
      border: 1px solid var(--gold-color);
      @include tablet {
        width: 48px;
        height: 48px;
        display: none;
      }
      svg {
        width: 20px;
      }
      &--prev {
        left: 0;
        svg {
          transform: rotate(-180deg);
        }
      }
      &--next {
        right: 0;
        svg {
          transform: rotate(0deg);
        }
      }

      &.slick-disabled {
        opacity: 0.7;
      }
    }
  }
}

.pack {
  width: 100%;
  @include desktop {
    width: 100%;
  }
  @include wide {
    width: 295px;
    margin: 0 auto;
  }
  &__image {
    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  &__desc {
    display: flex;
    row-gap: 12px;
    flex-direction: column;
    padding: 20px 0 0 12px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
    }
    p {
      color: #313131;
      font-size: 16px;
      line-height: 140%;
      @include tablet {
        font-size: 16px;
      }
      br {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }
  }
}