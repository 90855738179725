:root {
	--container-width: 1200px;
	--container-padding: 15px;

	--font-main: sans-serif;
	--font-accent: 'Montserrat', sans-serif;
	--font-titles: var(--font-accent);

	--gold-color: #BC8C46;
	--blue-color: #0040A1;

	--page-bg: #fff;
	--text-color: #000;
	--accent: #ac182c;
	--link-color: #2578c8;

	--green-color: #5E8058;


    --laptop-size: 1199px;
	--tablet-size: 959px;
	--mobile-size: 599px;
}