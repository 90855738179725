.second {
  position: relative;
  background: #fff url("../img/second-bg.png") no-repeat center bottom;

  &__items {
    width: 100%;
    display: flex;
    row-gap: 20px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      column-gap: 20px;
      flex-direction: row;
    }
    @include desktop {
      row-gap: 0;
      column-gap: 0;
      flex-direction: row;
    }
    &--small {
      max-width: 780px;
    }
    &--big {
      max-width: 1080px;
    }
  }

  &__product {
    display: none;
    @include desktop {
      width: 38%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    @include wide {
      width: auto;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    row-gap: 20px;
    position: relative;
    flex-direction: column;
    @include tablet {
      row-gap: 20px;
    }
    @include desktop {
      row-gap: 60px;
    }
  }
}

.benefit {
  padding: 14px;
  display: flex;
  column-gap: 12px;
  border-radius: 10px;
  background: #E8E8E8;
  @include tablet {
    width: 50%;
  }
  @include desktop {
    width: auto;
  }
  &__icon {
    width: 36px;
    height: 36px;
    display: flex;
    flex-shrink: 0;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    background: var(--gold-color);
    svg {
      width: 60%;
      height: 60%;
    }
  }
  &__desc {
    h3 {
      font-size: 16px;
      font-weight: 600;
      @include desktop {
        font-size: 14px;
      }
      @include wide {
        font-size: 16px;
      }
    }
    p {
      color: #2E2E2E;
      margin-top: 8px;
      line-height: 120%;
      @include desktop {
        font-size: 14px;
      }
      @include wide {
        font-size: 16px;
      }
      br {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }
  }
}