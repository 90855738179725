.accordion {
  width: 100%;

  &-item {
    padding: 12px 0;
    border-top: 1px solid var(--gold-color);
    @include tablet {
      padding: 16px 0;
    }
  }

  &-header {
    color: #000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    position: relative;
    padding: 4px 60px 4px 0;
    font-family: var(--font-accent);
    @include tablet {
      padding: 6px 0;
      font-size: 18px;
    }
    @include desktop {
      font-size: 20px;
      padding: 10px 0;
    }
    &-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 100%;
      @include tablet {
        width: 40px;
        height: 40px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        background: var(--green-color);
        background-color: transparent;
        background-image: image-set(
                        url('./../img/bottom-arrow.png') 1x,
                        url('./../img/bottom-arrow@2x.png') 2x);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }
  }

  &-content {
    color: #000;
    display: none;
    font-size: 14px;
    padding: 10px 0;
    line-height: 140%;
    font-family: var(--font-accent);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @include tablet {
      font-size: 16px;
    }
    ul {
      margin-top: 10px;
      li {
        color: #000;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 8px;
        position: relative;
        font-family: var(--font-accent);
        padding-left: 20px;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          width: 6px;
          height: 6px;
          margin: auto;
          background: #000;
          border-radius: 100%;
        }
      }
    }
  }
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-item.active .accordion-header-icon:after {
  transform: rotate(-180deg);
}