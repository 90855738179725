.first {
  position: relative;
  padding-top: 140px;
  background: #fff url("../img/first-bg.png") no-repeat right bottom;
  background-size: contain;
  @include tablet {
    padding-top: 180px;
    background-size: inherit;
  }
  &__title {
    text-align: center;
    h1 {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      @include tablet {
        font-size: 40px;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        @include tablet {
          font-size: 32px;
        }
      }
    }
  }
  
  &__content {
    margin-top: 20px;
    @include tablet {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-around;
    }
  }
  
  &__product {

  }
  
  &__list {
    margin-top: 20px;
    @include tablet {
      margin-top: 0;
    }
    &-ul {
      li {
        font-size: 14px;
        font-weight: 500;
        position: relative;
        padding-left: 24px;
        margin-bottom: 20px;
        @include tablet {
          font-size: 16px;
          padding-left: 30px;
          margin-bottom: 24px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: var(--gold-color);
          @include tablet {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    &-call {
      margin-top: 50px;
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 12px;
        @include tablet {
          font-size: 20px;
        }
        @include desktop {
          font-size: 18px;
        }
        @include wide {
          font-size: 20px;
        }
      }
    }
  }
}