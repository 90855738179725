.title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center;
  @include tablet {
    margin-bottom: 40px;
  }
  @include desktop {
    margin-bottom: 60px;
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    @include tablet {
      font-size: 30px;
    }
    @include desktop {
      font-size: 32px;
    }
    @include wide {
      font-size: 40px;
    }
  }
  h2 {
    font-size: 21px;
    margin-top: 20px;
    @include tablet {
      font-size: 32px;
      margin-top: 20px;
    }
  }
}