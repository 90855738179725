.room {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: #fff;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.4s ease-in-out;

  &__close {
    cursor: pointer;
    position: absolute;
    right: .5em;
    top: .5em;
    color: #1562D7;
    font-size: 2em;
    background: transparent;
    z-index: 10;
  }

  &__wrapper {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 2.5em 0;
    overflow-x: auto;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    transition: all 0.4s ease-in-out;
  }

  &__item {
    display: none;
    row-gap: 20px;
    margin: 0 auto;
    padding: 0 15px;
    column-gap: 40px;
    max-width: 1000px;
    align-items: center;
    @include tablet {
      padding: 0;
      row-gap: 40px;
    }
    &.room--active {
      display: flex;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
      }
    }
    &-slider {
      width: 100%;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      border-radius: 30px;
      @include tablet {
        width: 760px;
      }
      &:after {
        content: '';
        display: block;
        padding-bottom: 70%;
      }
      .aparts-slider {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        img {
          height: 100%;
        }

        .slick-track,
        .slick-list {
          height: 100%;
        }

        .slick-arrow {
          top: 0;
          bottom: 0;
          display: flex;
          user-select: none;
          align-items: center;
          justify-content: center;
          margin: auto;
          width: 36px;
          height: 36px;
          z-index: 1000;
          cursor: pointer;
          background: #fff;
          color: transparent;
          position: absolute;
          border-radius: 100%;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 12px;
            height: 12px;
            background: url("../img/bottom-arrow.png") no-repeat center center;
            background-size: contain;
          }
          &.slick-next {
            right: 12px;
            transform: rotate(-90deg);
          }

          &.slick-prev {
            left: 12px;
            &:after {
              transform: rotate(90deg);
            }
          }

          &.slick-disabled {
            opacity: 0.7;
          }
        }
      }
    }
    &-description {
      width: 100%;
      h2 {
        font-size: 21px;
        font-weight: 600;
        margin-bottom: 12px;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
      p {
        font-size: 14px;
        font-family: var(--font-accent);
        @include tablet {
          font-size: 16px;
        }
      }
      button {
        margin-top: 30px;
      }
    }
  }

  &__controls {
    left: 0;
    right: 0;
    top: 24px;
    width: 92%;
    margin: auto;
    position: absolute;
    @include tablet {
      width: 97%;
    }
    &-close,
    &-back {
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &-close {
      right: 0;
    }
    &-back {
      left: 0;
      position: relative;
      padding-left: 20px;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background: url("./../img/bottom-arrow.png") no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
      }
    }
  }
}

.room--active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transition: all 0.4s ease-in-out;
  .room__wrapper {
    opacity: 1;
    pointer-events: all;
    transition: all 0.4s ease-in-out;
  }
}

.react {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1011;
  overflow-x: auto;
  padding: 40px 0;
  opacity: 0;

  background: #eff1f4;
  pointer-events: none;
  transition: .3s ease-in all;
  
  &.react--active {
    opacity: 1;
    pointer-events: all;
    transition: .3s ease-in all;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
  
  &__controls {
    left: 0;
    right: 0;
    top: 24px;
    width: 92%;
    margin: auto;
    position: absolute;
    @include tablet {
      width: 97%;
    }
    &-close,
    &-back {
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &-close {
      right: 0;
    }
    &-back {
      left: 0;
      position: relative;
      padding-left: 20px;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background: url("./../img/bottom-arrow.png") no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
      }
    }
  }
}

.reservation {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 15px;
  @include tablet {
    padding: 0;
  }
  &__controls {
    left: 0;
    right: 0;
    top: 24px;
    width: 92%;
    margin: auto;
    position: absolute;
    @include tablet {
      width: 97%;
    }
    &-close,
    &-back {
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &-close {
      right: 0;
    }
    &-back {
      left: 0;
      position: relative;
      padding-left: 20px;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background: url("./../img/bottom-arrow.png") no-repeat center center;
        background-size: contain;
        transform: rotate(90deg);
      }
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 42px;
    }
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 18px;
    }
  }

  &__container {
    background: #fff;
    border-radius: 30px;
    @include tablet {
      display: flex;
    }
  }
  &__subtitle {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
    font-family: var(--font-accent);
    @include tablet {
      font-size: 21px;
    }
  }
  &__personal {
    width: 100%;
    padding: 24px 20px;
    @include tablet {
      width: 45%;
      padding: 32px 40px;
    }
  }
  &__room {
    width: 100%;
    background: #fafafa;
    padding: 24px 20px 0 20px;
    @include tablet {
      width: 55%;
      padding: 32px 40px 0 40px;
    }
  }
  &__buttons {
    width: 100%;
    margin-top: 20px;
  }
}


.selector {
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  &:last-child {
    border-bottom: none;
  }
  &__photo {
    display: flex;
    column-gap: 14px;
    align-items: center;
  }
  &__image {
    width: 100px;
    height: 50px;
    display: none;
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
    @include tablet {
      display: block;
    }
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
    }
  }
  &__name {
    h3 {
      font-size: 16px;
      font-weight: 500;
      font-family: var(--font-accent);
      @include tablet {
        font-size: 18px;
      }
    }
    p {
      opacity: .8;
      font-size: 14px;
      font-weight: 300;
      font-family: var(--font-accent);
    }
  }
  &__counter {
    display: flex;
    column-gap: 16px;
    align-items: center;
    &-control {
      width: 32px;
      color: #fff;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      position: relative;
      border-radius: 100%;
      background: var(--green-color);
      &--minus {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-image: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' className='s__pMo8XJ4PYXwDYpRlQu2f' data-test-id='icon' style='display: inline-block;'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14 8.75H2v-1.5h12v1.5Z'%3E%3C/path%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      &--plus {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' class='s__pMo8XJ4PYXwDYpRlQu2f' data-test-id='icon' style='display: inline-block;'%3E%3Cpath d='M8.75 7.25V2h-1.5v5.25H2v1.5h5.25V14h1.5V8.75H14v-1.5H8.75Z'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
    &-number {
      font-size: 18px;
      font-weight: 600;
      @include tablet {
        font-size: 21px;
      }
    }
  }
}

.error-message {
  color: red;
  display: block;
  font-size: 12px;
  margin-top: 2px;
}