.footer {
  padding: 32px 0 80px 0;
  background: #f1f1f1;
  @include tablet {
    padding: 40px 0 60px 0;
  }
  &--wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @include container;
  }

  &__menu {
    display: none;
    column-gap: 20px;
    @include tablet {
      display: flex;
    }
    li {
      a {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        font-family: var(--font-accent);
        position: relative;
        &:after {
          left: 0;
          width: 0;
          bottom: -4px;
          content: '';
          height: 1px;
          background: #000;
          position: absolute;
          transition: .3s ease-in all;
        }
        &:hover {
          &:after {
            width: 100%;
            transition: .3s ease-in all;
          }
        }
      }
    }
  }

  &__logo {
    display: flex;
    column-gap: 12px;
    margin-bottom: 20px;
    align-items: center;
    @include tablet {
      margin: 40px 0;
    }
    img {
      flex-shrink: 0;
    }
    span {
      color: #000;
      opacity: .4;
      font-size: 14px;
    }
    p {
      font-size: 21px;
      font-weight: 600;
    }
  }
  
  &__copyright {
    text-align: center;
    p, a {
      color: #000;
      opacity: .7;
      line-height: 140%;
    }
    
    a {
      text-decoration: underline;
    }
  }
}