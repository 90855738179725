html {
	overflow-x: hidden;
	background-color: #fff;
	scroll-behavior: smooth;
	font-family: var(--font-main);
	&.modal--opened {
		overflow: hidden;
	}
}

body {
	padding: 1% 1% 0 1%;
	background: #E8E8E8;
	color: var(--text-color);
	font-family: var(--font-main);
	&.modal--opened {
		overflow: hidden;
	}
}

main {
	display: flex;
	row-gap: 61px;
	flex-direction: column;
}

section {
	width: 100%;
	padding: 40px 15px;
	background: #fff;
	position: relative;
	border-radius: 20px;
	@include tablet {
		padding: 80px 40px;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		width: 60%;
		height: 1px;
		margin: auto;
		bottom: -31px;
		background: var(--gold-color);
	}

	&:last-child {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		&:after {
			display: none;

		}
	}
}

.container {
	@include container();
}

img {
	display: block;
}

a {
	color: var(--link-color);
}

.react-datepicker-wrapper {
	width: 100%;
}