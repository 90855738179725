.button {
  height: 50px;
  color: #fff;
  border: none;
  display: flex;
  padding: 0 24px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  align-items: center;
  border-radius: 6px;
  white-space: nowrap;
  justify-content: center;
  background: var(--gold-color);
  font-family: var(--font-accent);
  @include tablet {
    height: 52px;
    font-size: 14px;
  }
  @include desktop {
    height: 52px;
    font-size: 14px;
  }
  @include wide {
    height: 58px;
    font-size: 15px;
  }
  &:hover {
    background: var(--blue-color);
  }
  &--blue {
    background: var(--blue-color);
    &:hover {
      background: var(--gold-color);
    }
  }
}

